import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const TOS = () => (
    <Layout>
         <SEO title="TOS" description="Learn more about Emaildrop's Terms of Service."/>
        <main>
            <div className="main-wrapper">
                <div className="tos">
                    <h1 className="title">Terms of Service</h1>
                    <p className="paragraph-title">
                        These terms of service ("Terms") apply to your access and use of Emaildrop (the "Service"). 
                        Please read them carefully. If you access or use the Service, 
                        it means you agree to be bound by all of the terms below. So, before you use the
                        Service, please read all of the terms. If you don't agree to all of the 
                        terms below, please do not use the Service. Also, if a term does not make 
                        sense to you, please let us know by e-mailing admin@api.emaildrop.io.
                    </p>
                    <div className="contents">
                        <div className="content">
                            <h2>Service description</h2>
                            <p className="paragraph">
                                Emaildrop is a free disposable email provider. Users can 
                                generate email addresses using this website or alternatively use 
                                our public GraphQL API. Although, we impose no usage limits, users found to 
                                be purposely exploiting the service may be subject to IP banning (such as those 
                                involved in DDoS attack).
                            </p>
                            <p className="paragraph">
                                With regards to data, all emails recieved are processed and stored on our 
                                servers. If a user deletes an email from their inbox, it is also automatically 
                                deleted from our servers. Emaildrop does not keep backups longer than 1 hour 
                                and once an email is deleted, it is not recoverable. 
                            </p>
                            <p className="paragraph">
                                Emaildrop does not keep records of which email addresses you've used, how many times
                                you've viewed an email, or links your IP address with any email address you've used or
                                any emails you've recieved.
                            </p>
                        </div>
                        <div className="content">
                                <h2>Limitations</h2>
                                <p className="paragraph">
                                    In no event shall Emaildrop or its suppliers be liable for any damages (including, 
                                    without limitation, damages for loss of data or profit, or due to business 
                                    interruption) arising out of the use or inability to use the materials on any
                                    Emaildrop's websites, even if Emaildrop or a Emaildrop authorized representative has 
                                    been notified orally or in writing of the possibility of such damage. 
                                    Because some jurisdictions do not allow limitations on implied warranties, or 
                                    limitations of liability for consequential or incidental damages, 
                                    these limitations may not apply to you.
                                </p>
                        </div>
                        <div className="content">
                            <h2>Changes to terms and conditions</h2>
                            <p className="paragraph">
                                These Terms and Conditions may be modified or altered at any time, 
                                without notice. We recommend that you occasionally check the Terms 
                                and Conditions to ensure you have the most up to date information.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </main>
    </Layout>
)

export default TOS;